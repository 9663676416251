import React from 'react'
import BasicLayout from '../../components/layout/BasicLayout'
import { HTMLContent } from '../../components/Content';
import Button from "../../components/Button";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default () => {
    const back = (
        <div className={"l-component"} style={{"marginTop" : "-24px"}}>
            <Button type={"text"} click={() => window.history.back()}>
                <FontAwesomeIcon icon={faArrowLeft}  size="s" />
                <span>Back</span>
            </Button>
        </div>
    );

    return (
        <BasicLayout pageName={'thanks'}>

            <section>
                <header>
                    <h1 className="copy-header copy-header--l">
                        Message delivered
                    </h1>
                </header>

                <HTMLContent className="content"
                             content={"<p>Thanks, message received. I'll be in touch within 24-48 hours.</p>"} />

                {back}
            </section>
        </BasicLayout>
    )
}
